<template>
  <div>
    <div>
      <b-button
          v-b-modal.modal
          variant="primary"
      >
        <FeatherIcon icon="PlusIcon"></FeatherIcon> Add New Guest
      </b-button>
    </div>

    <validation-observer ref="simpleRules">
      <b-modal
        id="modal"
        title="Add New Guest"
        cancel-variant="outline-danger"
        size="lg"
        centered
    >
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group label="Contact No." label-for="contact_number">
              <b-input-group style="display: block">
                <b-row no-gutters>
                  <b-col cols="3">
                    <b-input-group-prepend>
                      <b-form-select id="country_code" v-model="form.contact_code" :options="countryOptions"
                                     value-field="country_code" text-field="country_code" />
                    </b-input-group-prepend>
                  </b-col>

                  <validation-provider #default="{ errors }" name="Contact Number" rules="required" slim>
                    <b-col cols="9">
                      <b-form-input
                          id="contact_number"
                          v-model="form.contact_number"
                          type="number"
                          min="0.00"
                          @focusout="verifyPhoneNumber"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-col>
                  </validation-provider>
                </b-row>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Salutation" label-for="salutation">
              <validation-provider #default="{ errors }" name="Salutation" rules="required">
                <b-form-select id="salutation"
                               v-model="form.title"
                               :options="salutationOptions"
                               value-field="name"
                               text-field="name"
                               :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Referrer" label-for="referrer">
              <b-form-select id="referrer"
                             v-model="form.upline_id"
                             :options="referrerOptions"
                             value-field="id"
                             text-field="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="Full Name" label-for="name">
              <validation-provider #default="{ errors }" name="Full Name" rules="required">
                <b-form-input
                    v-model="form.name"
                    id="name"
                    placeholder="Enter Full Name"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email" label-for="email">
              <b-form-input
                  v-model="form.email"
                  id="email"
                  placeholder="Enter Email"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <div slot="modal-footer">
        <b-button @click="validate" variant="primary">
          Confirm & Submit
        </b-button>
      </div>
    </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BCardText,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  BRow,
} from 'bootstrap-vue'
import FeatherIcon from "@core/components/feather-icon/FeatherIcon";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import { required, email } from '@validations'

export default {
  components: {
    BButton,
    BCardText,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BRow,
    FeatherIcon,
    ValidationObserver,
    ValidationProvider,
  },
  created() {
    this.$http.get(`api/dropdown/title`)
        .then(res => {this.salutationOptions = res.data.data})

    this.$http.get(`api/dropdown/referrer`)
      .then(res => {
        this.referrerOptions = res.data.data.map((referrer) => {
          return {
            id: referrer.id,
            text: `${referrer.name} (${referrer.phone_number})`
          }
        })
      })

    this.$http.get(`api/dropdown/country`)
        .then(res => {this.countryOptions = res.data.data})
  },
  data() {
    return {
      eventId: this.$route.params.eventId,
      form: {
        title: '',
        contact_code: 60,
        contact_number: '',
        upline_id: '',
        name: '',
        email: '',
      },
      selected: 'USA',
      countryOptions: [],
      referrerOptions: [],
      salutationOptions: [],
    }
  },
  methods: {
    validate() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.store()
        } else {
          reject()
        }
      })
    },
    verifyPhoneNumber() {
      let phoneNumber = `${this.form.contact_code}${this.form.contact_number}`

      this.$http.get(`/api/user/${phoneNumber}`)
          .then(res => {
            let data = res.data.data
            if(data){
              this.form.name = data.name
              this.form.title = data.title
              this.form.email = data.email
              this.form.upline_id = data.upline_id
            }
          })
    },
    store(){
      this.$http.post(`/api/event/${this.eventId}/attendance`, this.form)
          .then(() => {
            this.$swal({
              icon: 'success',
              text: 'New RSVP guest has been added.',
              showConfirmButton: false,
              timer: 2000
            }).then(e => {
              this.loading = false
              this.$bvModal.hide('modal')
              this.$emit('store')

              this.form.title = ''
              this.form.contact_code =
              this.form.contact_number = ''
              this.form.upline_id = ''
              this.form.name = ''
              this.form.email = ''
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'AlertCircleIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            });
          })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>