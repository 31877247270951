<template>
  <b-modal id="modal-update-prospect-status" hide-header centered>
    <b-container fluid class="p-1">
      <b-row>
        <h3 class="mx-auto">Update Prospect Status</h3>
      </b-row>
      <b-row class="pb-2">
        <p class="mx-auto">Update <b>{{guestDetail.name}}</b> to:</p>
      </b-row>
      <b-row class="pb-2">
        <b-col cols="6">
          <b-form-radio
              v-model="form.latest_sales_journey_status"
              name="latest_sales_journey_status"
              value="Quality Lead"
          >
            Quality Lead
          </b-form-radio>
        </b-col>
        <b-col cols="6">
          <b-form-radio
              v-model="form.latest_sales_journey_status"
              name="latest_sales_journey_status"
              value="Potential Referrer"
          >
            Potential Referrer
          </b-form-radio>
        </b-col>
      </b-row>
      <b-row class="pb-2">
        <b-col cols="12">
          <b-form-radio
              v-model="form.latest_sales_journey_status"
              name="latest_sales_journey_status"
              value="Dropped"
          >
            Dropped
          </b-form-radio>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-radio
              v-model="form.latest_sales_journey_status"
              name="disabled_latest_sales_journey_status"
              value="Visited Sales Gallery"
              disabled
          >
            Visited SG
          </b-form-radio>
        </b-col>
        <b-col cols="6">
          <b-form-radio
              v-model="form.latest_sales_journey_status"
              name="disabled_latest_sales_journey_status"
              value="KORA Member"
              disabled
          >
            KORA Member
          </b-form-radio>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <b-button variant="outline-danger" class="mx-auto" @click="onCancel">Cancel</b-button>
      <b-button variant="primary" class="mx-auto" @click="update">Confirm & Save</b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCol,
  BContainer,
  BFormRadio,
  BRow,
} from 'bootstrap-vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BCol,
    BContainer,
    BFormRadio,
    BRow,
  },
  props: ['guestDetail'],
  watch: {
    guestDetail: {
      immediate: true,
      handler (newVal) {
        this.form.latest_sales_journey_status = newVal.latest_sales_journey_status;
      }
    },
  },
  data() {
    return {
      form: {
        _method: 'PATCH',
        latest_sales_journey_status: '',
      },
    }
  },
  methods: {
    update(){
      this.$http.post(`/api/user/${this.guestDetail.id}/sales-journey-status`, this.form)
          .then(() => {
            this.$swal({
              icon: 'success',
              text: `Successfully updated ${this.guestDetail.name}'s status.`,
              showConfirmButton: false,
              timer: 2000
            }).then(e => {
              this.loading = false
              this.onCancel()
              this.$emit('update')
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'AlertCircleIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            });
          })
    },
    onCancel(){
      this.$bvModal.hide('modal-update-prospect-status')
    },
  }
}
</script>
