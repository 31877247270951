<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-breadcrumb class="pl-0" :items="breadcrumbItems" />
      </b-card>
      <b-card :title="eventTitle">
        <b-row class="mb-2">
          <b-col cols="4">
            <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
          </b-col>
          <b-col class="text-right">
            <modal-create @store="getAttendanceList" />
          </b-col>
        </b-row>

        <!-- table -->
        <b-table class="table-height" hover responsive sticky-header="true" no-border-collapse :per-page="pageLength"
          :current-page="currentPage" :items="rows" :fields="columns" :filter="searchTerm"
          :filter-included-fields="filterColumns" @filtered="onSearched">
          <template #cell(name)="data">
            <b-link
                :href="`https://kora-backoffice.kskgroup.com/referrer/${data.item.id}`"
                target="_blank"
            >
              {{ `${data.item.title} ${data.value}` }}
            </b-link>
          </template>

          <template #cell(latest_sales_journey_status)="data">
            <span>
              <b-badge :variant="prospectStatusVariant(data.value)">
                {{ data.value }}
              </b-badge>
            </span>
          </template>

          <template #cell(referrer_status)="data">
            <span>
              <b-badge :variant="referrerStatusVariant(data.value)">
                {{ data.value }}
              </b-badge>
            </span>
          </template>

          <template #cell(has_attended)="data">
            <span>
              <b-badge :variant="attendanceVariant(data.value)">
                {{ attendanceTitle(data.value) }}
              </b-badge>
            </span>
          </template>

          <template #cell(created_at)="data">
            <span>
              <span>{{ data.value | dateTime }}</span>
            </span>
          </template>

          <template #cell(actions)="data">
            <span class="d-flex">
              <b-dropdown variant="link" toggle-class="text-decoration-none p-1" no-caret>
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="text-body" />
                </template>
                <b-dropdown-item
                  :to="{ name: 'event.attendance.edit', params: { eventId: eventId, userId: data.item.user_id } }">
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>
                <!-- Dropdown Item: Assign/Reassign Catalyst -->
                <b-dropdown-item v-b-modal.modal-assign-catalyst @click="onSelectGuest(data.item)">
                  <feather-icon icon="SmileIcon" class="mr-50" />
                  <span>Assign/Reassign Catalyst</span>
                </b-dropdown-item>
                <!-- Dropdown Item: Update Prospect Status -->
                <b-dropdown-item v-b-modal.modal-update-prospect-status @click="onSelectGuest(data.item)">
                  <feather-icon icon="UserCheckIcon" class="mr-50" />
                  <span>Update Prospect Status</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </template>

        </b-table>

        <div class="mt-2 d-flex justify-content-between flex-wrap">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select v-model="pageLength" size="sm" :options="entriesPerPageOptions" class="w-50" />
          </b-form-group>

          <b-pagination v-model="currentPage" :total-rows="tableItemCount" :per-page="pageLength" size="sm"
            class="my-0" />
        </div>

        <assign-catalyst :guestDetail="selectedGuestDetail" @update="getAttendanceList" />
        <update-prospect-status :guestDetail="selectedGuestDetail" @update="getAttendanceList" />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBadge,
  BBreadcrumb,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BLink,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import modalCreate from './create'
import assignCatalyst from './_assign-catalyst'
import updateProspectStatus from './_update-prospect-status'
import moment from 'moment'

export default {
  components: {
    assignCatalyst,
    BBadge,
    BBreadcrumb,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BLink,
    BPagination,
    BRow,
    BTable,
    FeatherIcon,
    modalCreate,
    updateProspectStatus,
    VueGoodTable,
    ToastificationContent,
  },
  data() {
    return {
      eventId: this.$route.params.eventId,
      breadcrumbItems: [
        {
          text: 'Event Listing',
          to: { name: 'event.index' },
        },
        {
          text: 'RSVP Guest Listing',
          active: true,
        },
      ],
      eventTitle: '',
      selectedGuestDetail: '',
      pageLength: 10,
      entriesPerPageOptions: [10, 25, 50, 100],
      currentPage: 1,
      columns: [
        {
          label: 'Full Name',
          key: 'name',
          sortable: true,
        },
        {
          label: 'Contact No.',
          key: 'phone_number',
          sortable: true,
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          label: 'Prospect Status',
          key: 'latest_sales_journey_status',
          sortable: true,
        },
        {
          label: 'Referrer Status',
          key: 'referrer_status',
          sortable: true,
        },
        {
          label: 'Attendance',
          key: 'has_attended',
          sortable: true,
        },
        {
          label: 'Sales Catalyst (Primary)',
          key: 'closing_catalyst_1',
          sortable: true,
        },
        {
          label: 'Sales Catalyst (Pairing)',
          key: 'closing_catalyst_2',
          sortable: true,
        },
        {
          label: 'Occupation',
          key: 'occupation',
          sortable: true,
        },
        {
          label: "Guest's hobbies/lifestyle/ remark",
          key: 'remark',
          sortable: true,
        },
        {
          label: 'KORA Member (Referrer)',
          key: 'referrer',
          sortable: true,
        },
        {
          label: 'Channel',
          key: 'channel',
          sortable: true,
        },
        {
          label: 'Country',
          key: 'channel_country',
          sortable: true,
        },
        {
          label: 'Added By',
          key: 'added_by',
          sortable: true,
        },
        {
          label: 'Date Created',
          key: 'created_at',
          sortable: true,
        },
        {
          label: 'Actions',
          key: 'actions',
        },
      ],
      filterColumns: [
        'name', 'phone_number', 'email', 'latest_sales_journey_status', 'has_attended', 'closing_catalyst_1', 'closing_catalyst_2',
        'occupation', 'remark', 'referrer', 'channel', 'added_by', 'created_at'
      ],
      rows: [],
      searchTerm: '',
      searchResultCount: 0,
      attendance: [
        { title: 'No', variant: 'light-danger', value: false },
        { title: 'Yes', variant: 'light-success', value: true },
      ],
    }
  },
  filters: {
    dateTime: function (value) {
      return value ? moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/yyyy') : '-';
    },
  },
  computed: {
    attendanceTitle: function () {
      return status => this.attendance.find(option => option.value === status).title;
    },
    attendanceVariant: function () {
      return status => this.attendance.find(option => option.value === status).variant;
    },
    prospectStatusVariant() {
      const statusColor = {
        "KORA Member": 'primary',
        "Visited Sales Gallery": 'info',
        "Quality Lead": 'success',
        "Potential Referrer": 'warning',
        "Dropped": 'secondary',
      }

      return status => status ? statusColor[status] : 'none'
    },
    referrerStatusVariant() {
      const statusColor = {
        "Active": 'success',
        "Non-active": 'danger',
        "N/A": 'secondary',
        "Dropped": 'secondary',
      }

      return status => status ? statusColor[status] : 'none'
    },
    tableItemCount() {
      if (this.searchTerm) {
        return this.searchResultCount;
      } else {
        return this.rows.length;
      }
    },
  },
  created() {
    this.$http.get(`api/event/${this.eventId}`)
      .then(res => { this.eventTitle = res.data.data.name })

    this.getAttendanceList();
  },
  methods: {
    getAttendanceList() {
      this.$http.get(`/api/event/${this.eventId}/attendance`)
        .then(res => {
          this.rows = res.data.data;
        })
        .catch(error => {
          this.showErrorToast(error);
        });
    },
    showErrorToast(errorTxt) {
      this.$toast({
        component: ToastificationContent,
        props: {
          icon: 'AlertCircleIcon',
          text: errorTxt,
          variant: 'danger',
        },
      });
    },
    onSelectGuest(data) {
      this.selectedGuestDetail = data
    },
    onSearched(items, numOfResults) {
      this.searchResultCount = numOfResults;
      this.currentPage = 1;
    },
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

.dropdown-filter-width {
  width: 400px
}

.table-height {
  height: 500px;
  max-height: 500px;
}
</style>
