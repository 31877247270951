<template>
  <b-modal id="modal-assign-catalyst" hide-header centered>
    <b-container fluid class="p-1">
      <b-row>
        <h3 class="mx-auto">Assign/Reassign Catalyst</h3>
      </b-row>
      <b-row class="pb-2">
        <p class="mx-auto">Assign <b>{{guestDetail.name}}</b> to:</p>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Catalyst (Primary)" label-for="catalyst_primary_name">
            <b-form-select id="catalyst_primary_name"
                           v-model="form.catalyst_primary_name"
                           :options="catalystOptions"
                           value-field="name"
                           text-field="name"
            />
          </b-form-group>
          <b-form-group label="Catalyst (Pairing)" label-for="catalyst_pairing_name">
            <b-form-select id="catalyst_pairing_name"
                           v-model="form.catalyst_pairing_name"
                           :options="catalystOptions"
                           value-field="name"
                           text-field="name"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <b-button variant="outline-danger" class="mx-auto" @click="onCancel">Cancel</b-button>
      <b-button variant="primary" class="mx-auto" @click="update">Confirm & Save</b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCol,
  BContainer,
  BFormGroup,
  BFormSelect,
  BModal,
  BRow,
} from 'bootstrap-vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BCol,
    BContainer,
    BFormGroup,
    BFormSelect,
    BModal,
    BRow,
  },
  props: ['guestDetail'],
  created() {
    this.$http.get(`api/dropdown/catalyst`)
        .then(res => {this.catalystOptions = res.data.data})
  },
  watch: {
    guestDetail: {
      immediate: true,
      handler (newVal) {
        this.form.catalyst_primary_name = newVal.closing_catalyst_1;
        this.form.catalyst_pairing_name = newVal.closing_catalyst_2;
      }
    },
  },
  data() {
    return {
      form: {
        _method: 'PATCH',
        catalyst_primary_name: '',
        catalyst_pairing_name: '',
      },
      catalystOptions: [],
    }
  },
  methods: {
    update(){
      this.$http.post(`/api/user/${this.guestDetail.id}/catalyst`, this.form)
          .then(() => {
            this.$swal({
              icon: 'success',
              text: `Successfully updated ${this.guestDetail.name}'s catalysts.`,
              showConfirmButton: false,
              timer: 2000
            }).then(e => {
              this.loading = false
              this.onCancel()
              this.$emit('update')
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'AlertCircleIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            });
          })
    },
    onCancel(){ this.$bvModal.hide('modal-assign-catalyst') },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>